const BaseURL = 'https://app-end.awebolivia.org/api';

const titles = {
  siteName: 'AWE Bolivia',
}

const logos = {
  logoTopbar: 'logo_awe1.png',
  logoLogin: 'awe_logo.png',
  backgroundLogin: 'background.jpg',
}
export { 
  BaseURL,
  titles,
  logos
}
